<template>
    <div class="wysiwyg">
        <QuillEditor
            v-model:content="proxyModelValue"
            :placeholder="placeholder"
            :contentType="contentType"
            :theme="theme"
            :toolbar="toolbar"
        />
    </div>
</template>

<script>
    import { QuillEditor } from '@vueup/vue-quill';
    import '@vueup/vue-quill/dist/vue-quill.snow.css';

    export default {
        name: 'Wysiwyg',
        components: { QuillEditor },
        props: {
            modelValue: {
                type: [String, Object],
                default: '',
            },
            placeholder: {
                type: String,
                default: '',
            },
            contentType: {
                type: String,
                default: 'html',
                validator: (value) => value.match(/(html|delta|text)/),
            },
            theme: {
                type: String,
                default: 'snow',
                validator: (value) => value.match(/(snow|bubble)/),
            },
            toolbar: {
                type: [String, Array],
                default: '',
            },
            borderRadius: {
                type: Number,
                default: 5,
            },
            height: {
                type: Number,
                default: 150,
            },
        },
        emits: ['update:modelValue'],
        computed: {
            proxyModelValue: {
                get() {
                    return this.modelValue;
                },

                set(val) {
                    this.$emit('update:modelValue', val);
                },
            },
            componentBorderRadius() {
                return `${this.borderRadius}px`;
            },
            componentHeight() {
                return `${this.height}px`;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .wysiwyg {
        width: 100%;

        :deep(.ql-toolbar) {
            border: 2px solid $grey;
            border-top-left-radius: v-bind(componentBorderRadius);
            border-top-right-radius: v-bind(componentBorderRadius);
        }

        :deep(.ql-container) {
            border: 2px solid $grey;

            border-bottom-left-radius: v-bind(componentBorderRadius);
            border-bottom-right-radius: v-bind(componentBorderRadius);

            height: v-bind(componentHeight);
        }

        :deep(.ql-editor) {
            &::-webkit-scrollbar {
                width: 14px;
                appearance: none;
            }

            &::-webkit-scrollbar-thumb {
                border: 4px solid transparent;
                border-radius: $br-100;

                background-clip: padding-box;
                background-color: $primary-100;
            }
        }

        :deep(.ql-editor.ql-blank::before) {
            font-size: $font-16;
            font-style: normal;
            color: $grey-form-label;
        }
    }
</style>
